@media (max-width: 500px) {
  .grid-wrapper {
    grid-template-columns: 1fr;
  }
  .item-inner-border {
    border-top: none;
    border-right: none;
    border-bottom: 1px solid $light-tone !important;
    border-left: none;
  }
}
