@mixin back-highlighted($img, $deg, $start, $end) {
  background: -webkit-linear-gradient($deg, $start 30%, $end),
  url($img) no-repeat;
  background: -o-linear-gradient($deg, $start 30%, $end),
  url($img) no-repeat;
  background: -moz-linear-gradient($deg, $start 30%, $end),
  url($img) no-repeat;
  background: linear-gradient($deg, $start 30%, $end),
  url($img) no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@mixin cert-img($img) {
  background: -webkit-linear-gradient(0deg, $img-color-layer 30%, $img-color-layer),
  url($img) no-repeat center;
  background: -o-linear-gradient(0deg, $img-color-layer 30%, $img-color-layer),
  url($img) no-repeat center;
  background: -moz-linear-gradient(0deg, $img-color-layer 30%, $img-color-layer),
  url($img) no-repeat center;
  background: linear-gradient(0deg, $img-color-layer 30%, $img-color-layer),
  url($img) no-repeat center;
}
