:root {
  color: $dark-tone;
  font-family: "Roboto";
  font-weight: lighter;
  a {
    text-decoration: none;
    color: $accent-tone;
  }
  b {
    font-weight: bold;
  }
  .wrapper {
    max-width: 1000px;
    margin: 0px auto;
  }
  h2 {
    font-size: 170%;
    padding: 20px 0px;
    margin: 10px;
  }
  h3 {
    font-size: 140%;
    padding: 10px;
  }
  p {
    margin: 5px;
  }
  hr {
    height: 1px;
    border: 0px;
    margin: 0px;
    background-color: $light-tone;
  }
}
#cover-section {
  display: flex;
  flex-direction: row-reverse;
  min-height: 100vh;
  background-color: $mid-tone;
  @include back-highlighted("../image/back.jpg",
    -90deg,
    hsla(207, 63%, 60%, 0.7),
    hsla(207, 63%, 60%, 0));
  #cover-initials {
    width: 600px;
    text-align: center;
    background-color: $back-black;
    color: $front-white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 300%;
      margin: 10px;
      font-weight: bold;
    }

    .inner {
      margin: 20px 0px;
      max-width: 350px;

      .education {
        margin: 0px 0px 10px 0px;
      }
    }
  }
}

#experience, #skills, #certificates, #teaching-experience,
#professional-experience {
  text-align: center;
  padding: 50px 0px;
}

.grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.item-inner-border {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $light-tone;
  padding: 20px;
}
.item-inner-border:nth-child(1), .item-inner-border:nth-child(2) {
  border-top: none;
}
.item-inner-border:nth-child(2n+1) {
  border-left: none;
  border-bottom: none;
}
.item-inner-border:nth-child(2n) {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.item-back-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  margin: 20px 0px;
}

// #conditioning-coaching {
//   @include cert-img("../image/conditioning-coaching.png");
// }

#basketball { @include cert-img("../image/basketball.png"); }
#track-and-field { @include cert-img("../image/track_and_field.png"); }

// #swimming {
//   @include cert-img("../image/swimming.png");
// }

// #volleyball {
//   @include cert-img("../image/volleyball.png")
// }

#fitness { @include cert-img("../image/fitness.png"); }
#bodybuilding { @include cert-img("../image/bodybuilding.png"); }

// #trx {
//   @include cert-img("../image/trx.png");
// }

#research, #honors {
  padding: 50px 0px;
  text-align: justify;
  li {
    padding: 8px 0px;
    margin: 0px 40px;
  }
}

#references {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
  min-height: 50vh;
  background-color: $mid-tone;
  @include back-highlighted("../image/back.jpg",
  -90deg,
  hsla(207, 63%, 60%, 0.7),
  hsla(207, 63%, 60%, 0)
  );
  .references-data {
    border-radius: 10px;
    width: 600px;
    text-align: center;
    background-color: $back-black;
    color: $front-white;
    padding: 40px;
    margin: auto auto;
    #socials {
      padding: 20px 0px;
      a {
        font-size: 250%;
        padding: 20px;
        color: $front-white;
      }
    }
  }
}
