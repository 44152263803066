@import 'reset';
@import 'var'; // must come right after reset
// @import 'keyframe';
@font-face {
	font-family: "Roboto";
	src: url("../font/Roboto-Regular.ttf");
}
@font-face {
	font-family: "Roboto";
	src: url("../font/Roboto-Bold.ttf");
	font-weight: bold;
}
@font-face {
	font-family: "Roboto";
	src: url("../font/Roboto-Light.ttf");
	font-weight: lighter;
}
@import 'mixin'; // must come before main
@import 'main';
@import 'media'; // must come after main
